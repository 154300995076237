:root {
  --neutral-primary: #35383d;
  --neutral-secondary: #ffffff;
  --neutral-tertiary: #f7eee8;
  --accent-primary: #105368;
  --positive-primary: #bac4b2;
  --positive-primary-dark: #4e8046;
  --negative-primary: #a11f1f;
  --negative-primary-dark: #871a1a;
  --negative-primary-desaturated: #a14141;
  --neutral-primary-light: #676a6d;
  --neutral-primary-lighter: #9a9b9e;
  --neutral-secondary-dark: #f3f2f2;
  --neutral-secondary-darker: #e1dede;
  --neutral-tertiary-dark: #ebd5c6;
  --neutral-tertiary-darker: #e3c5af;
  --accent-primary-dark: #0c3f4f;
  --dark-black-with-opacity: #0000001f;
  --dark-black: #000000;
  // Brand Colors
  --terracota-100: #b0654d;
  --terracota-75: #c48b79;
  --terracota-50: #d7b2a6;
  --terracota-20: #efe0db;
  --peony-100: #d49a9a;
  --peony-75: #dfb3b3;
  --peony-50: #e9cccc;
  --peony-20: #f6ebeb;
  --shell-100: #f5e4da;
  --shell-75: #f7ebe3;
  --shell-50: #faf1ec;
  --shell-20: #fdfaf8;
  --ocean-100: #105368;
  --ocean-75: #4c7e8e;
  --ocean-50: #87a9b3;
  --ocean-20: #cfdde1;
  --slate-100: #35383d;
  --slate-75: #676a6d;
  --slate-50: #9a9b9e;
  --slate-20: #ebebec;
  --grey-100: #c3bebe;
  --grey-75: #d2cece;
  --grey-50: #e1dede;
  --grey-20: #f3f2f2;
  --wheat-100: #d8ac8c;
  --wheat-75: #e3c5af;
  --wheat-50: #ebd5c6;
  --wheat-20: #f7eee8;
  --honey-100: #f2b14e;
  --honey-75: #f5c47a;
  --honey-50: #f8d8a6;
  --honey-20: #fcefdc;
  --sage-100: #a3b198;
  --sage-75: #bac4b2;
  --sage-50: #d1d8cb;
  --sage-20: #edefea;
  --sky-100: #72acbe;
  --sky-75: #95c1ce;
  --sky-50: #b8d5de;
  --sky-20: #e3eef2;
  --pomegranate-100 :#C87070;
  --pomegranate-33 :#C8707054;
  --turmeric-100: #D68D63;
  --turmeric-33: #D68D6354;
  --lavender-100: #8292E7;
  --lavender-33: #8292E754;
  --neutral-tertiary-darker-33: #e3c5af54;
  --peony-33: #dfb3b354;
  --honey-33: #f5c47a54;
  --positive-primary-33: #bac4b254;
  --sky-33: #95c1ce54;
  --red: #ff1a79;
  --box-shadow: rgba(0, 0, 0, 0.04);
  --is-missing: rgba(255, 255, 255, 0.6);
  --orange-50: #F5CFB9;
  --orange-75: #E3C0AB;
}
